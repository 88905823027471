var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderSource === "order_source_scheme"
        ? _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("套餐" + _vm._s(index + 1)),
              ]),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c("div", { staticClass: "table-title" }, [_vm._v("本品")]),
                  _c(
                    "vxe-table",
                    {
                      staticClass: "table-body",
                      attrs: { data: item.bpSchemeProductList || [] },
                    },
                    [
                      _c("vxe-table-column", {
                        attrs: { title: "序号", width: "70px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var rowIndex = ref.rowIndex
                                return [_vm._v(_vm._s(rowIndex + 1))]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "产品编码", field: "productCode" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "产品名称", field: "productName" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "单价", field: "price" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "单位", field: "unitName" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "数量", field: "quantity" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "table-title" }, [_vm._v("赠品")]),
                  _c(
                    "vxe-table",
                    {
                      staticClass: "table-body",
                      attrs: { data: item.zpSchemeProductList || [] },
                    },
                    [
                      _c("vxe-table-column", {
                        attrs: { title: "序号", width: "70px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var rowIndex = ref.rowIndex
                                return [_vm._v(_vm._s(rowIndex + 1))]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "产品编码", field: "productCode" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "产品名称", field: "productName" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "单价", field: "price" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "单位", field: "unitName" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "数量", field: "quantity" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          })
        : [
            _c(
              "vxe-table",
              { attrs: { data: _vm.list } },
              [
                _c("vxe-table-column", {
                  attrs: { title: "序号", width: "70px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [_vm._v(_vm._s(rowIndex + 1))]
                      },
                    },
                  ]),
                }),
                _c("vxe-table-column", {
                  attrs: { title: "产品编码", field: "productCode" },
                }),
                _c("vxe-table-column", {
                  attrs: { title: "产品名称", field: "productName" },
                }),
                _c("vxe-table-column", {
                  attrs: { title: "单价", field: "price" },
                }),
                _c("vxe-table-column", {
                  attrs: { title: "单位", field: "unitName" },
                }),
                _c("vxe-table-column", {
                  attrs: { title: "数量", field: "quantity" },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }