<template>
  <div>
    <template v-if="orderSource==='order_source_scheme'">
      <div v-for="(item, index) in list" :key="index">
        <div class="title">套餐{{index+1}}</div>
        <div class="table-container">
          <div class="table-title">本品</div>
          <vxe-table :data="item.bpSchemeProductList||[]" class="table-body">
            <vxe-table-column title="序号" width="70px">
              <template v-slot="{ rowIndex }">{{ rowIndex +1}}</template>
            </vxe-table-column>
            <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
            <vxe-table-column title="单价" field="price"></vxe-table-column>
            <vxe-table-column title="单位" field="unitName"></vxe-table-column>
            <vxe-table-column title="数量" field="quantity"></vxe-table-column>
          </vxe-table>
          <div class="table-title">赠品</div>
          <vxe-table :data="item.zpSchemeProductList||[]" class="table-body">
            <vxe-table-column title="序号" width="70px">
              <template v-slot="{ rowIndex }">{{ rowIndex +1}}</template>
            </vxe-table-column>
            <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
            <vxe-table-column title="单价" field="price"></vxe-table-column>
            <vxe-table-column title="单位" field="unitName"></vxe-table-column>
            <vxe-table-column title="数量" field="quantity"></vxe-table-column>
          </vxe-table>
        </div>
      </div>
    </template>
    <template v-else>
      <vxe-table :data="list">
        <vxe-table-column title="序号" width="70px">
          <template v-slot="{ rowIndex }">{{ rowIndex +1}}</template>
        </vxe-table-column>
        <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
        <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
        <vxe-table-column title="单价" field="price"></vxe-table-column>
        <vxe-table-column title="单位" field="unitName"></vxe-table-column>
        <vxe-table-column title="数量" field="quantity"></vxe-table-column>
      </vxe-table>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(curVal) {
        if (curVal) {
          if (curVal.orderSource) {
            this.orderSource = curVal.orderSource;
          }
          if (curVal.orderSource === 'order_source_scheme') {
            this.list = curVal.sfaActSchemeSetmealRespVos || [];
          } else {
            this.list = curVal.sfaVisitStepOrderItems || [];
          }
        }
        // if (curVal && Array.isArray(curVal)) this.sfaVisitStepOrderItemRespVos = curVal;
      },
    },
  },
  data() {
    return {
      orderSource: '',
      list: [],
    };
  },
};
</script>
<style lang="less" scoped>
.title{
  padding-left: 10px;
  font-size: 14px;
}
.table-container{
  padding-left: 30px;
}
.table-title{
  padding-bottom: 10px;
  font-size: 14px;
}
.table-body{
  margin-bottom: 20px;
}
</style>
