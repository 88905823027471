var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "120px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.orderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "orderCode", $$v)
                      },
                      expression: "baseInfo.orderCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户编码" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.clientCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "clientCode", $$v)
                      },
                      expression: "baseInfo.clientCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.clientName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "clientName", $$v)
                      },
                      expression: "baseInfo.clientName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "realName", $$v)
                      },
                      expression: "baseInfo.realName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "职位名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.posName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "posName", $$v)
                      },
                      expression: "baseInfo.posName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属组织" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "orgName", $$v)
                      },
                      expression: "baseInfo.orgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "联系人" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.contacts,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "contacts", $$v)
                      },
                      expression: "baseInfo.contacts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "联系电话" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.telephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "telephone", $$v)
                      },
                      expression: "baseInfo.telephone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "期望到货日期" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.expectReceive,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "expectReceive", $$v)
                      },
                      expression: "baseInfo.expectReceive",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下单日期" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.orderTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "orderTime", $$v)
                      },
                      expression: "baseInfo.orderTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级客户" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.customerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "customerName", $$v)
                      },
                      expression: "baseInfo.customerName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收货地址" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "address", $$v)
                      },
                      expression: "baseInfo.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单状态" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.approvalStatusName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "approvalStatusName", $$v)
                      },
                      expression: "baseInfo.approvalStatusName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单来源" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.baseInfo.orderSourceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "orderSourceName", $$v)
                      },
                      expression: "baseInfo.orderSourceName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", readonly: "", autosize: "" },
                    model: {
                      value: _vm.baseInfo.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfo, "remarks", $$v)
                      },
                      expression: "baseInfo.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }