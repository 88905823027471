<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

import DetailHead from './components/detail_head.vue';
import DetailTable from './components/detail_row.vue';

formCreate.component('sfaOrderManageOrderDetailHead', DetailHead);
formCreate.component('sfaOrderManageOrderDetailTable', DetailTable);

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    getDetail() {
      const id = (this.formConfig && this.formConfig.row && this.formConfig.row.id) ? this.formConfig.row.id : this.id;
      request.post('/sfa/sfaWorkDailyController/loadEditPageForWorkbenchOrder', { bizId: id }).then((res) => {
        if (!res.success) return false;
        this.setValue({
          detailInfo: res.result,
          detailTable: res.result,
        });
      });
    },
  },
  /** @desc 重新赋值rule,反正感觉怪怪的有问题 */
  async created() {
    await this.getFormRule('sfaCenter_orderManage_orderDetail_form', 'CRM20201229000000269');
    this.getDetail();
  },
};
</script>
