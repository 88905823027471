<template>
  <el-form label-width="120px">
    <el-row>
      <el-col :span="8">
        <el-form-item label="订单号">
          <el-input v-model="baseInfo.orderCode" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户编码">
          <el-input v-model="baseInfo.clientCode" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户名称">
          <el-input v-model="baseInfo.clientName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="人员名称">
          <el-input v-model="baseInfo.realName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="职位名称">
          <el-input v-model="baseInfo.posName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="所属组织">
          <el-input v-model="baseInfo.orgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系人">
          <el-input v-model="baseInfo.contacts" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系电话">
          <el-input v-model="baseInfo.telephone" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="期望到货日期">
          <el-input v-model="baseInfo.expectReceive" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="下单日期">
          <el-input v-model="baseInfo.orderTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="上级客户">
          <el-input v-model="baseInfo.customerName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="收货地址">
          <el-input v-model="baseInfo.address" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="订单状态">
          <el-input v-model="baseInfo.approvalStatusName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="订单来源">
          <el-input v-model="baseInfo.orderSourceName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注">
          <el-input type="textarea" v-model="baseInfo.remarks" readonly autosize></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  props: {
    value: Object,
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.baseInfo = curVal;
        }
      },
    },
  },
  data() {
    return {
      baseInfo: {},
    };
  },
};
</script>
